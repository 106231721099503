import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { BooleanAppSearchParam, ColumnTextAlignment } from '@japieglobal/shared/src/components';

export type ColumnType =
  | 'image'
  | 'logo'
  | 'price'
  | 'basic'
  | 'delta'
  | 'click'
  | 'mileage'
  | 'options'
  | 'equipment'
  | 'redirect'
  | 'flag'
  | 'trash';
export type HeaderType = 'trash' | 'country' | 'basic';
export interface CarWindowColumn {
  field: keyof CarResult;
  flex?: number;
  headerName?: string;
  columnType?: ColumnType;
  clickName?: BooleanAppSearchParam; // TODO: exchange with paramskey that will be made speceifcly for all the clicks
  align?: ColumnTextAlignment;
  minWidth?: number;
  cellClassName?: string;
  headerClassName?: string;
  headerType?: HeaderType;
  headerAlign?: ColumnTextAlignment;
  headerDescription?: string;
}
export const CarWindowColumns: CarWindowColumn[] = [
  { field: 'image', columnType: 'image', flex: 2.5, cellClassName: 'noPadding' },
  { field: 'id', columnType: 'logo', flex: 2, cellClassName: 'noPadding' },
  { field: 'price', headerName: 'PRICE', columnType: 'price', flex: 2 },
  { field: 'stock_days', headerName: 'DAYS', minWidth: 63, headerDescription: 'STAND_TIME' },
  { field: 'stat', headerName: 'DELTA', columnType: 'delta' },
  { field: 'build', headerName: 'BUILD_YEAR_ABR', columnType: 'click', clickName: BooleanAppSearchParam.CLICK_BUILD },
  {
    field: 'model_year',
    headerName: 'MODEL_YEAR_ABR',
    columnType: 'click',
    clickName: BooleanAppSearchParam.CLICK_MODEL_YEAR,
  },
  { field: 'mileage', headerName: 'KM', columnType: 'mileage' },
  { field: 'hp', headerName: 'PK', columnType: 'click', clickName: BooleanAppSearchParam.CLICK_HP, flex: 0.5 },
  { field: 'options', headerName: 'OPTIONS', flex: 15, columnType: 'options', align: 'left', headerAlign: 'left' },
  { field: 'equipment', flex: 4, columnType: 'equipment', align: 'left' },
  { field: 'url', columnType: 'redirect', flex: 0.5, minWidth: 25 },
  {
    field: 'country',
    columnType: 'flag',
    flex: 1.5,
    minWidth: 25,
    headerType: 'country',
    headerClassName: 'noPadding',
  },
  {
    field: 'is_banned',
    columnType: 'trash',
    flex: 0.5,
    headerClassName: 'noPadding',
    headerType: 'trash',
    minWidth: 25,
  },
];
