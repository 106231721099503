import { Grid2 } from '@mui/material';
import React from 'react';
import { useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { DisplayHints } from '@japieglobal/shared/src/components';
import { TaxationTopFilters } from './top-side-filters/taxation-top-filter';
import { ShoppingCartButton } from '../../taxation-and-monitoring-shared/buttons/shopping-cart-button';
import { MailButton } from '../../taxation-and-monitoring-shared/buttons/mail-button/mail-button';
import { ResultTableTrace } from '../../taxation-and-monitoring-shared/buttons/result-table-trace-button';
import { SaveTaxation } from '../../saved-taxations/save-taxation';
import { OwnStockCount } from '../../taxation-and-monitoring-shared/own-stock-count/own-stock-count';
import { RedirectPurchaseButton } from '../../taxation-and-monitoring-shared/buttons/redirect-purchase-button';
import { ResultTopDealers } from '../../taxation-and-monitoring-shared/result-top-dealers';
import { CarWindowResult } from '@japieglobal/shared/src/api';

interface TaxationCarViewProps {
  car: CarResult | undefined;
  selectedCountry: string;
  clickDealers: { [country: string]: string | undefined };
  setClickDealers: (clickDealer: { [country: string]: string | undefined }) => void;
  windowResults: { [country: string]: CarWindowResult | undefined };
  evaluateCar: (country: string, pageIndex?: number, pricingTarget?: number) => void;
}
export const TaxationCarView = ({
  car,
  windowResults,
  clickDealers,
  selectedCountry,
  setClickDealers,
  evaluateCar,
}: TaxationCarViewProps) => {
  const [searchParams] = useAppSearchParams();
  const { url } = searchParams;

  return (
    <Grid2 container spacing={2}>
      <TaxationTopFilters />
      <Grid2 container alignItems={'center'} columnSpacing={2} size={4}>
        {url !== undefined && (
          <Grid2>
            <RedirectPurchaseButton url={url} />
          </Grid2>
        )}
        <Grid2>
          <ShoppingCartButton car={car || windowResults[selectedCountry]?.car} />
        </Grid2>
        <Grid2>
          <SaveTaxation car={car || windowResults[selectedCountry]?.car} />
        </Grid2>
        <Grid2>
          <MailButton isTaxation car={car || windowResults[selectedCountry]?.car} />
        </Grid2>
        <Grid2>
          <ResultTableTrace car={windowResults[selectedCountry]?.car} />
        </Grid2>
        {car?.stat?.own_supply_window_count !== undefined && car.stat.own_supply_window_count > 1 && (
          <Grid2>
            <OwnStockCount car={car} />
          </Grid2>
        )}
        <Grid2 display={'flex'} flex={1}>
          <ResultTopDealers
            dealers={windowResults[selectedCountry]?.top_dealers ?? []}
            selected={clickDealers[selectedCountry]}
            onSelectTopDealer={(dealer) => {
              setClickDealers({ ...clickDealers, [selectedCountry]: dealer });
              evaluateCar(selectedCountry);
            }}
          />
        </Grid2>
      </Grid2>
      <DisplayHints />
    </Grid2>
  );
};
