import React from 'react';
import { useTranslation } from 'react-i18next';
import { VideoDialog } from './video-dialog';

export const EtrVideoDialog = ({ videoClicked, handleClose }: { videoClicked: boolean; handleClose: () => void }) => {
  const { t, i18n } = useTranslation();

  const getCountryUrl = () => {
    if (i18n.language == 'nl') return 'https://share.synthesia.io/embeds/videos/f29c7224-4f83-4408-bcb7-4c63767aa928';
    if (i18n.language == 'de') return 'https://share.synthesia.io/embeds/videos/e0b65406-f0bd-44b0-a60d-8bc26e08dced';
    return 'https://share.synthesia.io/embeds/videos/354f3943-4ff3-43c6-bcb6-5d6461948a48';
  };

  return (
    <VideoDialog videoUrl={getCountryUrl()} videoClicked={videoClicked} handleClose={handleClose} title={t('ETR')} />
  );
};
