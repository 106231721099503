import React, { useCallback, useEffect, useState } from 'react';
import { addCarBasedOnRank } from '../../utils/add-car-to-list-rank-based';
import { reorderList } from '../../utils/reorder-list';
import { TaxationErrorView } from './taxation-error-view';
import { CarResult } from '@japieglobal/shared/src/types';
import { CarWindowResult } from '@japieglobal/shared/src/api';
import { usePriceRankingStates } from '../../states/price-ranking';
import { useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarWindowPageCounter } from '../../taxation-and-monitoring-shared/car-compare-table/car-compare-page-counter';
import { CarWindowTable } from '../../taxation-and-monitoring-shared/car-compare-table/car-compare-table';

interface TaxationListViewProps {
  windowResult?: CarWindowResult;
  evaluateCar: (pageIndex?: number, pricingTarget?: number) => void;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
}

export const TaxationListView = React.memo(
  ({ windowResult, evaluateCar, pageIndex, setPageIndex }: TaxationListViewProps) => {
    const { resetPriceRanking, setPriceDiscount, setPricingTarget, pricingTarget } = usePriceRankingStates();
    const [, setAppSearchParams] = useAppSearchParams([]);

    const [competitorCars, setCompetitorCars] = useState<CarResult[]>([]);

    useEffect(() => {
      if (windowResult?.car) {
        setCompetitorCars(addCarBasedOnRank(windowResult.car, windowResult.window, windowResult.pagable.page_size));
      }
    }, [windowResult]);

    const changePageIndex = (page: number) => {
      setPageIndex(page);
      evaluateCar(page, pricingTarget);
    };

    const reorder = useCallback(
      (source: number, target: number) => {
        const newTarget = competitorCars[target]?.rank?.counter;

        setCompetitorCars((prevState) => {
          return reorderList(prevState, source, target);
        });
        if (newTarget !== undefined) {
          setPriceDiscount(undefined);
          setPricingTarget(newTarget);
          evaluateCar(pageIndex, newTarget);
        }
      },
      [competitorCars, evaluateCar, pageIndex, setPricingTarget, setPriceDiscount],
    );

    const removeRanking = () => {
      resetPriceRanking();
      setPageIndex(0);
      setAppSearchParams({ pricing_target: undefined });
      evaluateCar();
    };

    const pageCount = windowResult?.pagable?.page_count ?? 0;

    return (
      <>
        {windowResult?.car && (
          <>
            <CarWindowTable
              cars={competitorCars}
              ownCar={windowResult?.car}
              reorder={reorder}
              removeRanking={removeRanking}
              taxationView={true}
              error={windowResult?.error}
            />
            {pageCount > 0 && (
              <CarWindowPageCounter changePage={changePageIndex} pageCount={pageCount} currentPage={pageIndex} />
            )}
          </>
        )}
        {windowResult?.error && <TaxationErrorView windowResult={windowResult} />}
      </>
    );
  },
);
