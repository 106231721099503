import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompetitorDialog } from './car-window/car-window-dialog';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { CarMainNormalView } from './car-main-normal-view';
import { compareMemoProps } from '../utils/compare-react-props';
import { useSearchParams } from 'react-router-dom';
import { getUrlParams } from '../utils/searchParamsMapper';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarMessageService } from '@japieglobal/shared/src/car-message/car-message-service';
import { CarMessageContextType } from '@japieglobal/shared/src/car-message/car-message-types';
import { CarMessageContext } from '@japieglobal/shared/src/car-message/car-message-context';
import { getCarsList } from '@japieglobal/shared/src/api';
import { Pageable } from '@japieglobal/shared/src/types/pagination';

export const CarsPage = React.memo(() => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const [carsLoading, setCarsLoading] = useState(true);
  const [reloadCarListCounter, setReloadCarListCounter] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [appSearchParams] = useAppSearchParams();

  const [cars, setCars] = useState<CarResult[]>([]);
  const [pageable, setPageable] = useState<Pageable | undefined>(undefined);
  const [carMessageContext, setCarMessageContext] = useState<CarMessageContextType | undefined>(undefined);

  useEffect(() => {
    if (cars.length) {
      CarMessageService.INSTANCE.getMessages(cars.map((c) => c.id!)).then(setCarMessageContext);
    } else {
      setCarMessageContext(undefined);
    }
  }, [cars]);

  const reloadCarList = useCallback(
    (id: number) => {
      if (cars.length > 1) {
        setCars(cars.filter((car) => car.id != id));
      } else {
        setReloadCarListCounter(reloadCarListCounter + 1);
      }
    },
    [cars, reloadCarListCounter],
  );

  const carListParams = useMemo(() => {
    const params = getUrlParams(appSearchParams);
    delete params['page_index'];
    delete params['window_car_id'];
    delete params['show_extra_filters'];
    delete params['show_trash_bin'];
    delete params['priceDiscount'];
    return params;
  }, [appSearchParams]);

  const getCarList = async (finalPageIndex: number, finalPageSize: number, carListParams) => {
    return getCarsList({ ...carListParams, page_index: finalPageIndex, page_size: finalPageSize });
  };
  const windowCarId = Number(searchParams.get('window_car_id'));

  useDebouncedEffect(
    () => {
      // This check is to prevent is from fetching data when the window is opened
      if (!windowCarId) {
        setCarsLoading(true);
        setSearchParams((prev) => {
          prev.delete('page_index');
          return prev;
        });

        getCarList(0, 25, carListParams)
          .then((res) => {
            setCars(res.results);
            setPageable(res.pagable);
            setCarsLoading(false);
          })
          .catch((err) => {
            setCarsLoading(false);
            snackbarErrorHandler(err);
          });
      }
    },
    500,
    [reloadCarListCounter, JSON.stringify(carListParams)],
  );

  const handleGetMoreResults = () => {
    const finalPageIndex = Number(searchParams.get('page_index')) || 0;
    const finalPageSize = Number(searchParams.get('page_size')) || 25;
    setSearchParams((prev) => {
      prev.set('page_index', String(finalPageIndex + 1));
      return prev;
    });
    setCarsLoading(true);

    getCarList(finalPageIndex + 1, finalPageSize, carListParams)
      .then((res) => {
        setCars([...cars, ...res.results]);
        setPageable(res.pagable);
        setCarsLoading(false);
      })
      .catch((err) => {
        setCarsLoading(false);
        snackbarErrorHandler(err);
      });
  };

  const setShowDialog = useCallback(
    (val) => {
      setSearchParams((prev) => {
        if (val) prev.set('window_car_id', String(val));
        else prev.delete('window_car_id');
        return prev;
      });
    },
    [setSearchParams],
  );

  return (
    <CarMessageContext.Provider value={{ value: carMessageContext, setter: setCarMessageContext }}>
      <>
        <CarMainNormalView
          loading={carsLoading}
          cars={cars}
          handleGetMoreResults={handleGetMoreResults}
          pageable={pageable}
          reloadCarList={reloadCarList}
        />
        {!!windowCarId && (
          <CompetitorDialog
            reloadCarList={reloadCarList}
            showDialog={!!windowCarId}
            setShowDialog={setShowDialog}
            carId={windowCarId}
          />
        )}
      </>
    </CarMessageContext.Provider>
  );
}, compareMemoProps());
