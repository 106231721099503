import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import { IconButton } from '@mui/material';
import { AdminEditType, AdminPageType, AdminQueryStateKey } from '../hooks/admin-query-state';
import { Link, useParams, useSearchParams } from 'react-router-dom';

interface IconProps {
  onClick: () => void;
  disabled?: boolean;
}
export const DeleteIcon = ({ onClick, disabled }: IconProps) => (
  <span style={{ marginRight: '5px' }}>
    <IconButton onClick={onClick} disabled={disabled} color="warning" aria-label="delete">
      <DeleteForeverIcon fontSize="large" />
    </IconButton>
  </span>
);

export const DownloadIcon = ({ onClick, disabled }: IconProps) => (
  <span style={{ marginRight: '5px' }}>
    <IconButton onClick={onClick} disabled={disabled} color="primary" aria-label="download">
      <CloudDownloadIcon fontSize="large" />
    </IconButton>
  </span>
);

export const MissingLocationsIcon = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => (
  <IconButton onClick={onClick} disabled={disabled} color="primary" aria-label="add">
    <WrongLocationIcon fontSize="large" />
  </IconButton>
);
const getEditTypeFromPageType = (pageType: AdminPageType) => {
  switch (pageType) {
    case AdminPageType.SERVICE_ACCOUNTS:
    case AdminPageType.PURCHASE_ACCOUNTS:
      return AdminEditType.SERVICE_ACCOUNTS;
    case AdminPageType.ADMINS:
      return AdminEditType.ADMIN;
    case AdminPageType.SCRAPE_ADMIN_USERS:
      return AdminEditType.SCRAPE_USER;
    default:
      return undefined;
  }
};
export const AddIcon = () => {
  const { pageType } = useParams();

  const getAddUserPageUrl = () => {
    const editType = getEditTypeFromPageType(pageType as AdminPageType);

    const appendEditType = editType ? `?${AdminQueryStateKey.EDIT_TYPE}=${editType}` : '';
    return `/admin/${AdminPageType.ADD_USER}${appendEditType}`;
  };

  return (
    <IconButton component={Link} to={getAddUserPageUrl()} aria-label="add">
      <AddCircleIcon color="primary" fontSize="large" />
    </IconButton>
  );
};

interface TableActionsIconsProps {
  userId: string;
  showActivity?: boolean;
  hideDuplicate?: boolean;
}
export const TableActionsIcons = ({ userId, showActivity = true, hideDuplicate = false }: TableActionsIconsProps) => {
  const { pageType } = useParams();
  const [params] = useSearchParams();

  const getUserActivityPageURL = () => {
    if (userId) {
      return `/admin/${AdminPageType.ACTIVITY_LIST}?${AdminQueryStateKey.USER_ID}=${userId}`;
    }
    return '#';
  };

  const getEditUserPageUrl = () => {
    if (userId) {
      const editType = getEditTypeFromPageType(pageType as AdminPageType);

      let url = `/admin/${AdminPageType.EDIT_USER}?${AdminQueryStateKey.USER_ID}=${userId}`;
      if (editType) {
        url = url + `&${AdminQueryStateKey.EDIT_TYPE}=${editType}`;
      }
      if (params.get('page')) {
        url = url + `&page=${params.get('page')}`;
      }
      return url;
    }
    return '#';
  };

  const getDuplicateUserPageUrl = () => {
    if (userId) {
      const editType = getEditTypeFromPageType(pageType as AdminPageType);

      let url = `/admin/${AdminPageType.DUPLICATE_USER}?${AdminQueryStateKey.USER_ID}=${userId}`;
      if (editType) {
        url = url + `&${AdminQueryStateKey.EDIT_TYPE}=${editType}`;
      }
      if (params.get('page')) {
        url = url + `&page=${params.get('page')}`;
      }
      return url;
    }
    return '#';
  };

  return (
    <>
      <IconButton to={getEditUserPageUrl()} component={Link}>
        <EditIcon color="primary" />
      </IconButton>
      {!hideDuplicate && (
        <IconButton to={getDuplicateUserPageUrl()} component={Link}>
          <ContentCopyIcon color="primary" />
        </IconButton>
      )}
      {showActivity && (
        <IconButton to={getUserActivityPageURL()} component={Link}>
          <TroubleshootIcon color="primary" />
        </IconButton>
      )}
    </>
  );
};
