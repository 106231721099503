import React from 'react';
import { TableCell } from '../../../monitoring/results/car-popup-styles';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AprBreakdown, CarResult } from '@japieglobal/shared/src/types';

interface CarTurnOverTableRowProps<K extends keyof AprBreakdown> {
  rowKey: K;
  value?: AprBreakdown[K];
  isPercent?: boolean;
  countAsBonus?: boolean;
}
const CarTurnOverTableRow = <K extends keyof AprBreakdown>({
  rowKey,
  value,
  isPercent,
  countAsBonus,
}: CarTurnOverTableRowProps<K>) => {
  const { t } = useTranslation();

  if (value === undefined) return <></>;

  const { bound, points } = value;

  const getBoundValue = () => {
    if (typeof bound === 'number') {
      if (isPercent) {
        const percentage = Math.round(bound * 100 - (countAsBonus ? 100 : 0));
        return `${countAsBonus && percentage > 0 ? '+' : ''}${percentage}%`;
      }
      return bound;
    }
    if (typeof bound === 'string') {
      return bound
        .split(',')
        .map((option) => t(option.toUpperCase()))
        .join(', ');
    }
  };

  return (
    <TableRow key={rowKey}>
      <TableCell style={{ textAlign: 'left' }}>{t(rowKey.toUpperCase())}</TableCell>
      <TableCell style={{ textAlign: 'center' }}>{points}</TableCell>
      <TableCell>{getBoundValue()}</TableCell>
    </TableRow>
  );
};

export const CarTurnoverTableTiny = ({ car: { apr_breakdown } }: { car: CarResult }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('RATING')}</TableCell>
          <TableCell sx={{ textAlign: 'center' }}>{t('CORRECTION')}</TableCell>
          <TableCell>{t('VALUE')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <CarTurnOverTableRow rowKey="window_size" value={apr_breakdown?.window_size} />
        <CarTurnOverTableRow rowKey="window_unlocked" value={apr_breakdown?.window_unlocked} />
        <CarTurnOverTableRow rowKey="etr" value={apr_breakdown?.etr} />
        <CarTurnOverTableRow rowKey="sensitivity" value={apr_breakdown?.sensitivity} isPercent />
        <CarTurnOverTableRow rowKey="mileage_mean" countAsBonus value={apr_breakdown?.mileage_mean} isPercent />
        <CarTurnOverTableRow
          rowKey="own_supply_window_ratio"
          value={apr_breakdown?.own_supply_window_ratio}
          isPercent
        />
      </TableBody>
    </Table>
  );
};
