import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserContext } from '../../shared/src/user-context';
import { AdminMenuPage } from './admin/menu/admin-menu-page';
import { AdminPage } from './admin/admin-page';
import { AuthActivatePage } from './auth/auth-activate-page';
import { AuthForgotPage } from './auth/auth-forgot-page';
import { AuthLoginPage } from './auth/auth-login-page';
import { AuthResetPage } from './auth/auth-reset-page';
import { AuthResetPhonePage } from './auth/auth-reset-phone-page';
import { CarsPage } from './monitoring/cars-page';
import { UploadPage } from './monitoring/upload/upload-page';
import { TaxationPage } from './taxatie/taxation-page';
import { TinyUrlPage } from './tiny/tiny-url-page';
import { hasRequiredSettingsPermissions } from '@japieglobal/shared/src/components/top-row/first-bar/buttons/settings-button';
import { RedirectPage } from './redirect-page';
import { PageType } from './super-admin/super-admin-page-container';
import { RdwGetCar } from './super-admin/components/rdw/rdw-get-car';
import { RdwRefreshCar } from './super-admin/components/rdw/rdw-refresh-car';
import { RdwModelYearInfo } from './super-admin/components/rdw/rdw-modelyear-info';
import { RdwManualAdjustment } from './super-admin/components/rdw/rdw-manual-adjustment';
import { RdwAddCarToCatalog } from './super-admin/components/rdw/rdw-add-car-to-catalog';
import { EditTranslations } from './super-admin/components/edit-translations';
import { ClusterManagement } from './super-admin/components/cluster-management/cluster-management';
import { OptionsManagement } from './super-admin/components/options-management/options-management';
import { OptionsManagementEdit } from './super-admin/components/options-management/options-management-edit';
import { settings } from '@japieglobal/shared/src/settings';
import { OptionsManagementCreate } from './super-admin/components/options-management/options-management-create';
import { getUser, User, UserPermission } from '@japieglobal/shared/src/api';
import { JapieAppWrapper } from '@japieglobal/shared/src/japie-app-wrapper';
import { RdwAddModelYear } from './super-admin/components/rdw/rdw-add-model-year';
import { SalesMap } from './super-admin/sales-map/sales-map';
import { CarErrorsDownload } from './super-admin/components/car-errors-download';
import { Taxations } from './saved-taxations/taxations';
import { RdwGetPlatesByCarType } from './super-admin/components/rdw/rdw-get-plates-by-car-type';
import { DealerUsersProvider } from './states/dealer-users-context';
import { ClicksConfigs } from './super-admin/components/clicks-configs/clicks-configs';
import { ClicksConfigEdit } from './super-admin/components/clicks-configs/clicks-config-edit';
import { ClicksConfigCreate } from './super-admin/components/clicks-configs/clicks-config-create';
import { Jpcglc } from './terms-and-conditions/jpcglc';
import { PrivacyTerms } from './terms-and-conditions/privacy-terms';
import { LinkCarToStandardOptions } from './super-admin/components/options-management/Link-car-to-standard-options/link-car-to-standard-options';
import { hasAnyPermission, hasPermission, isAdmin, isScrapeUser, isSuperAdmin } from '@japieglobal/shared/src/utils';
import { EtrExplanation } from './video-explanations/etr-explanation';

export const AppContainer = () => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { i18n } = useTranslation();

  const defaultPath = useMemo(() => {
    if (user) {
      if (hasPermission(user, UserPermission.MONITORING)) return '/cars';
      if (hasPermission(user, UserPermission.TAXATION)) return '/taxatie';
      if (isScrapeUser(user)) return '/admin-scrape';
    }
    return '/cars';
  }, [user]);

  const checkLoggedIn = useCallback(() => {
    getUser()
      .then((res) => {
        setUser(res);
        Sentry.setUser({ email: res.email });
        i18n.changeLanguage(res.language.toLowerCase()).then();
      })
      .catch(() => {
        setUser(undefined);
        Sentry.setUser(null);
        i18n.changeLanguage(settings().fallbackLanguage).then();
      })
      .finally(() => setLoaded(true));
  }, [i18n]);

  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  if (!loaded) return <></>;
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <JapieAppWrapper user={user}>
      <DealerUsersProvider>
        {user ? (
          <UserContext.Provider value={{ user, setUser }}>
            <SentryRoutes>
              <Route path="/jpcglc" element={<Jpcglc />} />
              <Route path="/jpcps" element={<PrivacyTerms />} />
              <Route path="/privacyverklaring" element={<PrivacyTerms />} />
              {hasPermission(user, UserPermission.MONITORING) && <Route path="/cars" element={<CarsPage />} />}
              {hasRequiredSettingsPermissions(user) && <Route path="/admin" element={<AdminMenuPage />} />}
              {(isAdmin(user) || (isScrapeUser(user) && hasPermission(user, UserPermission.SCRAPE_MANAGEMENT))) && (
                <Route path="/admin/:pageType" element={<AdminPage />} />
              )}
              {hasAnyPermission(user, [
                UserPermission.FILE_VALUATION,
                ...Object.values(UserPermission).filter((permission) => permission.startsWith('upload_')),
              ]) && (
                <>
                  <Route path="/valuate" element={<UploadPage />} />
                  <Route path="/upload" element={<UploadPage />} />
                </>
              )}
              {hasPermission(user, UserPermission.TAXATION) && (
                <>
                  <Route path="/taxatie" element={<TaxationPage />} />
                  <Route path="/taxations" element={<Taxations />} />
                </>
              )}
              {isSuperAdmin(user) && (
                <Route path={`/super-admin`}>
                  <Route
                    index
                    element={<Navigate to={settings().country == 'nl' ? PageType.PLATE : PageType.CLICKS_CONFIG} />}
                  />
                  {settings().country == 'nl' && (
                    <>
                      <Route path={PageType.PLATE} element={<RdwGetCar />} />
                      <Route path={PageType.PLATES} element={<RdwGetPlatesByCarType />} />
                      <Route path={PageType.REFRESH} element={<RdwRefreshCar />} />
                      <Route path={PageType.MODEL_YEAR} element={<RdwModelYearInfo />} />
                      {hasPermission(user, UserPermission.SYSTEM_ADMIN) && (
                        <>
                          <Route path={PageType.MANUAL_ADJUSTMENT} element={<RdwManualAdjustment />} />
                          <Route path={PageType.CATALOG} element={<RdwAddCarToCatalog />} />
                          <Route path={PageType.CO2_MODEL_YEAR} element={<RdwAddModelYear />} />
                          <Route path={PageType.TRANSLATIONS} element={<EditTranslations />} />
                          <Route path={PageType.CLUSTER_MANAGEMENT} element={<ClusterManagement />} />
                        </>
                      )}
                    </>
                  )}
                  <Route path={PageType.MAP} element={<SalesMap />} />
                  <Route path={PageType.OPTIONS_MANAGEMENT}>
                    <Route path="" element={<OptionsManagement />} />
                    {hasPermission(user, UserPermission.SYSTEM_ADMIN) && (
                      <>
                        <Route path="edit/:optionId" element={<OptionsManagementEdit />} />
                        <Route path="create" element={<OptionsManagementCreate />} />
                        <Route path="car-options-linking" element={<LinkCarToStandardOptions />} />
                      </>
                    )}
                  </Route>
                  <Route path={PageType.CAR_ERRORS} element={<CarErrorsDownload />} />
                  <Route path={PageType.CLICKS_CONFIG}>
                    <Route path="" element={<ClicksConfigs />} />
                    {hasPermission(user, UserPermission.SYSTEM_ADMIN) && (
                      <>
                        <Route path="edit/:dealer/:propertyId" element={<ClicksConfigEdit />} />
                        <Route path="create" element={<ClicksConfigCreate />} />
                      </>
                    )}
                  </Route>
                </Route>
              )}
              <Route path="/tiny/:key" element={<TinyUrlPage />} />
              {(hasPermission(user, UserPermission.SCRAPE_MANAGEMENT) || isScrapeUser(user)) && (
                <Route path="/admin-scrape" element={<RedirectPage />} />
              )}
              <Route path="/etr-explanation" element={<EtrExplanation />} />
              <Route path="*" element={<Navigate replace to={defaultPath} />} />
            </SentryRoutes>
          </UserContext.Provider>
        ) : (
          <SentryRoutes>
            <Route path="/jpcglc" element={<Jpcglc />} />
            <Route path="/privacyverklaring" element={<PrivacyTerms />} />
            <Route path="/auth/activate" element={<AuthActivatePage />} />
            <Route path="/auth/reset" element={<AuthResetPage />} />
            <Route path="/auth/reset/phone" element={<AuthResetPhonePage />} />
            <Route path="/auth/forgot" element={<AuthForgotPage type={'password'} />} />
            <Route path="/auth/forgot/phone" element={<AuthForgotPage type={'phoneNumber'} />} />
            <Route path="*" element={<AuthLoginPage setLoggedIn={checkLoggedIn} />} />
          </SentryRoutes>
        )}
      </DealerUsersProvider>
    </JapieAppWrapper>
  );
};
