import React from 'react';
import { Paper } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types';
import { CarWindowResult } from '@japieglobal/shared/src/api';
import { TaxationCarView } from './taxation-car-view';
import { ResultOtherCountries } from './result-other-countries/result-other-countries';
import { LoadingWrapper } from '@japieglobal/shared/src/components';

interface TaxationTopViewProps {
  car: CarResult | undefined;
  windowResults: { [country: string]: CarWindowResult | undefined };
  currentCountry: string;
  selectedCountry: string;
  clickDealers: { [country: string]: string | undefined };
  setClickDealers: (clickDealer: { [country: string]: string | undefined }) => void;
  setSelectedCountry: (selectedCountry: string) => void;
  windowIsLoading: { [country: string]: boolean };
  isLoadingCarInformation: boolean;
  evaluateCar: (country: string, pageIndex?: number, pricingTarget?: number) => void;
  pageIndexes: { [country: string]: number };
}

export const TaxationTopView = ({
  car,
  windowResults,
  setSelectedCountry,
  selectedCountry,
  clickDealers,
  setClickDealers,
  windowIsLoading,
  isLoadingCarInformation,
  evaluateCar,
  pageIndexes,
}: TaxationTopViewProps) => (
  <LoadingWrapper isLoading={isLoadingCarInformation}>
    <Paper sx={{ padding: '10px' }}>
      <TaxationCarView
        selectedCountry={selectedCountry}
        clickDealers={clickDealers}
        setClickDealers={setClickDealers}
        car={car}
        windowResults={windowResults}
        evaluateCar={evaluateCar}
      />
      <ResultOtherCountries
        windowIsLoading={windowIsLoading}
        windowResults={windowResults}
        setSelectedCountry={setSelectedCountry}
        selectedCountry={selectedCountry}
        clickDealers={clickDealers}
        pageIndexes={pageIndexes}
      />
    </Paper>
  </LoadingWrapper>
);
