import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

export const VideoDialog = ({
  videoUrl,
  title,
  videoClicked,
  handleClose,
}: {
  videoUrl: string;
  title: string;
  videoClicked: boolean;
  handleClose: () => void;
}) => {
  return (
    <Dialog open={videoClicked} onClose={handleClose} maxWidth="md" fullWidth disableEnforceFocus>
      <DialogContent>
        <iframe
          src={videoUrl + '?autoplay=1'}
          title={title}
          allow="fullscreen"
          style={{ width: '100%', height: '400px', border: 'none' }}
        />
      </DialogContent>
    </Dialog>
  );
};
