import React from 'react';
import { TableCell } from '../../../monitoring/results/car-popup-styles';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { styled, Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types';

const TableCellAlignEnd = styled(TableCell)({
  textAlign: 'end',
});

export type HighlightCell = 'target_value_exex' | 'target_value' | 'topdown_value' | 'topdown_value_exex';

interface CarPriceOverviewProps {
  car: CarResult;
  highlightedCell: HighlightCell;
  hideTopdownPrices: boolean;
  country: string;
}
export const CarPriceOverview = ({ car, highlightedCell, hideTopdownPrices, country }: CarPriceOverviewProps) => {
  const { t } = useTranslation();

  const getTaxFromNet = (value?: number): number | '' => (car.vat && value ? Math.round(value * (car.vat / 100)) : '');

  const getColor = (theme: Theme) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCellAlignEnd sx={{ textAlign: 'end' }}>{t('GROSS')}</TableCellAlignEnd>
          {country == 'nl' && <TableCellAlignEnd sx={{ textAlign: 'end' }}>{t('BPM')}</TableCellAlignEnd>}
          <TableCellAlignEnd sx={{ textAlign: 'end' }}>{t('TAX')}</TableCellAlignEnd>
          <TableCellAlignEnd sx={{ textAlign: 'end' }}>{t('NET')}</TableCellAlignEnd>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('VVP')}</TableCell>
          <TableCellAlignEnd sx={highlightedCell === 'target_value' ? getColor : undefined}>
            {car.rank?.target_value}
          </TableCellAlignEnd>
          {country === 'nl' && <TableCellAlignEnd>{car.taxes?.[0]?.value}</TableCellAlignEnd>}
          <TableCellAlignEnd>{getTaxFromNet(car.rank?.target_value_exex)}</TableCellAlignEnd>
          <TableCellAlignEnd sx={highlightedCell === 'target_value_exex' ? getColor : undefined}>
            {car.rank?.target_value_exex}
          </TableCellAlignEnd>
        </TableRow>
        {car.topdown_value_breakdown &&
          Object.entries(car.topdown_value_breakdown).map((entry) => (
            <TableRow key={entry[0]}>
              <TableCell>{t(entry[0].toUpperCase())}</TableCell>
              <TableCellAlignEnd>{entry[1]}</TableCellAlignEnd>
              {country === 'nl' && <TableCellAlignEnd></TableCellAlignEnd>}
              <TableCellAlignEnd>{getTaxFromNet(car.topdown_value_exex_breakdown?.[entry[0]])}</TableCellAlignEnd>
              <TableCellAlignEnd>{car.topdown_value_exex_breakdown?.[entry[0]]}</TableCellAlignEnd>
            </TableRow>
          ))}
        {!hideTopdownPrices && (
          <TableRow>
            <TableCell>{t('PURCHASE')}</TableCell>
            <TableCellAlignEnd sx={highlightedCell === 'topdown_value' ? getColor : undefined}>
              {car.topdown_value}
            </TableCellAlignEnd>
            {country === 'nl' && <TableCellAlignEnd>{car.taxes?.[0]?.value}</TableCellAlignEnd>}
            <TableCellAlignEnd>{getTaxFromNet(car.topdown_value_exex)}</TableCellAlignEnd>
            <TableCellAlignEnd sx={highlightedCell === 'topdown_value_exex' ? getColor : undefined}>
              {car.topdown_value_exex}
            </TableCellAlignEnd>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
