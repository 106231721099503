import React, { useMemo } from 'react';
import { ResponsiveScatterPlotCanvas } from '@nivo/scatterplot';
import { styled, TableBody } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useTranslation } from 'react-i18next';
import { BpmResult } from '@japieglobal/shared/src/api';
import { ProgressIndicator } from '@japieglobal/shared/src/components';
import { Table, TableCell } from '../../monitoring/results/car-popup-styles';

const margin = {
  left: 40,
  bottom: 25,
  top: 10,
  right: 40,
};

const Wrapper = styled(`div`)({
  height: '100px',
  width: '250px',
  position: 'relative',
});

export const ResultTaxesChart = ({ car, bpmCars }: { car: CarResult; bpmCars: BpmResult[] }) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    if (bpmCars) {
      let ownCarEntry;
      const otherCars: { id: string; data: { x: number; y: number }[] } = { id: 'other_cars', data: [] };
      const licensePlate = car.license_plate?.replaceAll('-', '');
      bpmCars.forEach((bpmCar, index) => {
        if (licensePlate !== bpmCar.license_plate) {
          otherCars.data.push({
            x: index,
            y: bpmCar.bruto_bpm / car!.taxes![0].data.value_init,
          });
        } else {
          ownCarEntry = {
            id: 'own_car',
            data: [{ x: index, y: 1 }],
          };
        }
      });

      return [otherCars, ownCarEntry];
    }
    return undefined;
  }, [car, bpmCars]);

  if (!bpmCars?.length) {
    return <></>;
  }

  let yValues: number[] = [];
  if (bpmCars) {
    const maxBpmRatio = Math.ceil(bpmCars[bpmCars.length - 1].bruto_bpm / car.taxes![0].data.value_init);
    if (maxBpmRatio < 3) {
      yValues = [0, 0.5, 1, 1.5, 2, 3];
    } else {
      yValues = [0, maxBpmRatio / 2, maxBpmRatio];
    }
  }
  // TODO: use theme colors
  const colorFunction = (params) => {
    if (params.serieId === 'own_car') return '#485A69';
    return '#36C34F';
  };

  const tooltipFunction = (params) => {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <Table>
          <TableBody>
            {Object.entries((bpmCars || {})[params.node.xValue]).map(([k, v]) => (
              <tr>
                <TableCell>{t(k.toUpperCase())}</TableCell>
                <TableCell>{v as string}</TableCell>
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Wrapper>
      {bpmCars ? (
        <ResponsiveScatterPlotCanvas
          data={data!}
          margin={margin}
          axisBottom={{
            tickValues: [],
          }}
          tooltip={tooltipFunction}
          colors={colorFunction}
          axisLeft={{ tickValues: yValues }}
        />
      ) : (
        <ProgressIndicator />
      )}
    </Wrapper>
  );
};
