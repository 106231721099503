import { AppSearchParamKey } from '@japieglobal/shared/src/components';
import {
  CsmReport,
  CsmReportKey,
  SalesReportRow,
  updateDealerAccount,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api';

export type CellRender =
  | 'basic'
  | 'link'
  | 'health'
  | 'date'
  | 'comments'
  | 'superAdminSelect'
  | 'healthSelect'
  | 'statusSelect'
  | 'actions';
export type HoverRender = 'score' | 'table';

export interface CsmColumn {
  field: keyof CsmReport;
  headerName: string;
  headerDescription: string;
  flex?: number;
  type: string;
  cellRender: CellRender;
  hoverRender?: HoverRender;
  searchParamKey?: AppSearchParamKey;
  selectType: string;
}

export interface UpdateDealerAccountFunctionProps<K extends keyof Partial<UpdateDealerAccountResponse>> {
  row: SalesReportRow | CsmReport;
  key: K;
  value: Partial<UpdateDealerAccountResponse>[K];
}

export const handleUpdateDealerAccount = <K extends keyof Partial<UpdateDealerAccountResponse>>({
  row,
  key,
  value,
}: UpdateDealerAccountFunctionProps<K>): Promise<UpdateDealerAccountResponse> =>
  updateDealerAccount({
    name: row[CsmReportKey.DEALER],
    health: row[CsmReportKey.HEALTH],
    status: row[CsmReportKey.STATUS],
    csm: row[CsmReportKey.CSM],
    [key]: value,
  });

const updateDealerAccountFunction = <K extends keyof Partial<UpdateDealerAccountResponse>>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  row,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  key,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
}: UpdateDealerAccountFunctionProps<K>): void => {};

export type UpdateDealerAccountFuncType = typeof updateDealerAccountFunction;
